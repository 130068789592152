.block.floorplans {
  * {
    box-sizing: border-box;
  }
  padding: 64px 0;
  ul {
    margin: 0;
  }
  .inner {
    // max-width: 100vw;
  }
  .description {
    padding: 80px 15px;
    p,
    ul {
      margin-bottom: 10px;
    }
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 20px;
    }
  }

  .wrapper {
    position: relative;
    min-height: 750px;
    padding: 0 15px;

    .sidebar.opened {
      transform: translate(-15px);
      form {
        visibility: visible;
      }
    }
    .sidebar.closed .open {
      display: block;
    }
    .sidebar.closed .close {
      display: none;
    }
    .sidebar.opened .open {
      display: none;
    }
    .sidebar.opened .close {
      display: block;
    }
    .sidebar {
      box-shadow: 1px 1px 0px #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      position: absolute;
      width: 240px;
      height: 100%;
      padding: 30px 15px;
      background-color: #f1f1f1;
      transform: translate(calc(-100% - 15px));
      transition: all 0.3s ease;
      color: @primaryHeading;
      z-index: 10;
      button {
        position: absolute;
        top: 15px;
        left: 100%;
        width: 50px;
        min-width: unset;
        height: 50px;
        padding: 4px 0 0 0;
        border-radius: 0;
        background-color: @primary;
        box-shadow: 1px -1px 4px #ccc;
        span, i, svg {
          pointer-events: none;
          color: #fff;
          font-size: 1.6rem;
        }
      }

      .title {
        margin-bottom: 20px;
        * {
          font-size: 1.8rem;
        }
      }
      form {
        transition: all 0.3s ease;
        visibility: hidden;
        fieldset {
          border: none;
          display: flex;
          flex-direction: column !important;
          padding: 0;
          margin: 0 0 16px 0;
          legend {
            font-weight: 500;
            font-size: 2.1rem;
            margin-bottom: 5px;
            color: @primaryHeading;
          }
          label {
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 5px;
            color: @primaryHeading;
            .select-wrapper {
              position: relative;
              select {
                font-size: 1.4rem;
                padding: 0 10px;
                line-height: 2;
                height: 32px;
                width: 100%;
                border-radius: 0;
                text-indent: 0;
                -webkit-appearance: none; /* Chrome, Safari, Opera */
                -moz-appearance: none; /* Firefox */
                appearance: none; /* Default */
              }
              i, svg {
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
                font-weight: 900;
                pointer-events: none;
                color: @primaryHeading;
                pointer-events: none;
              }
            }
          }
        }
      }
    }

    .floorplan-list {
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 30px 15px;
      width: 100%;
      list-style: none;
      .floorplan {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f1f1f1;
        margin-bottom: 32px;
        border: 1px solid #ccc;
        .image {
          width: 100%;
          padding: 20px 40px;
          margin-bottom: 16px;
          border-bottom: 1px solid #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 180px;
            border: 1px solid #ccc;
          }
        }
        .floorplan-details {
          width: 100%;
          display: flex;
          flex-direction: column;
          h2,
          h3,
          h4,
          h5 {
            font-family: @heading;
            font-size: 1.8rem;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 20px;
            font-weight: 400;
            color: @primaryHeading;
            opacity: 0.5;
            padding: 0 15px;
            font-weight: bold;
          }
          ul {
            width: 100%;
            list-style: none;
            li {
              padding: 8px 15px 0 15px;
              color: @primaryHeading;
              font-size: 1.36rem;
              letter-spacing: 1px;
              border-top: 1px solid #ccc;
              &:first-child {
                border-top: none;
              }
              strong {
                line-height: 2;
                margin-right: 5px;
                text-transform: uppercase;
                font-weight: 400;
                opacity: 0.5;
              }
            }
          }
          span {
            line-height: 2;
            color: @primaryText;
          }
          .details {
            // display: none;
            padding: 8px 15px 0;
						font-size: 1.36rem;
						color: @primaryText;
						line-height: 2;
						margin-bottom: 10px;
						font-style: italic;
          }

          .tour-flag {
            color: @primaryText;
            font-size: 1.36rem;
            padding: 4px 15px;
            font-style: italic;
            opacity: 0.8;
          }
        }
      }
      .floorplan.hidden {
        display: none;
      }
      #empty-list {
        color: @primaryHeading;
        .no-results {
          font-family: @heading;
          font-size: 3rem;
          font-weight: 700;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
        .try-again {
          font-size: 1.6rem;
          font-family: @main;
          font-weight: 500;
          margin-bottom: 10px;
        }
      }
      #empty-list.hidden {
        display: none;
      }
    }
  }

  .vp(1000px, {
	.wrapper {
		display: flex;
		flex-direction: row;
    padding: 0;
		.sidebar {
			position: relative;
			transform: translate(0);
			height: auto;
      .title {
        * {
          font-size: 3.6rem;
        }
      }
			button {
				display: none;
			}
      form {
        visibility: visible;
      }
		}
		.floorplan-list {
			.floorplan {
				flex-direction: row;
				.image {
					width: 20%;
					height: 100%;
					min-width: 180px;
					margin: 0;
					border: none;
				}
				.floorplan-details {
					padding: 20px 15px 0 15px;
					width: 80%;
					h2 {
						padding: 20px 0 0 0;
					}
					ul {
						display: flex;
						flex-direction: row;
						margin-bottom: 1.6rem;
						li {
							display: flex;
							flex-direction: column;
							border: none;
							flex-grow: 1;
							padding: 8px 0;
							strong {
								color: @primaryText;
								border-bottom: 1px solid #cccccc;
								margin: 0;
							}
						}
					}
					.details, .tour-flag {
            padding: 0;

            // Limit text to 2 lines
            // overflow: hidden;
            // text-overflow: ellipsis;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 2;
            // line-clamp: 2;
            // word-break: break-word;
					}
				}
			}
		}
	}
});
}

#rs-builder {
  .block.floorplans {
    .wrapper {
      .sidebar {
        z-index: 0;
      }
    }
  }
}