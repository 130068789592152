html {
	font-size: 62.5%;
}

body{
	font-family: @main;
	font-size: 1.75rem;
	line-height: 1.75;
	color: @text;
	font-family: @main;
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: @maxwidth;
	width: 100%;
}

#cookie-box {
	z-index: 999999 !important;
}

.slbContentOuter {
	button {
		box-shadow: none !important;
		font-weight: 300 !important;
		background-color: transparent !important;
	  }
	.slbCloseBtn {
		right: 15px !important;
		top: 10px !important;
		height: unset !important;
		width: unset !important;
	  }

	.slbCaption {
	  padding-right: 8ch;
	  height: 4em;
	  font-size: 16px;
	  bottom: 10px !important;
	  overflow: hidden;
	  text-align: left;
	}

	.slbCaption-text {
	  overflow: hidden !important;
	  text-overflow: ellipsis !important;
	  text-align: left !important;
	  * {
		font-size: 16px !important;
	  }
	}
	.slbCaption-counter {
	  height: 4em !important;
	  font-size: 16px !important;
	  position: absolute;
	  right: 0;
	}
  }