article.text-content {
    position: relative;
	padding: 90px 15px 60px;

	p, ul, ol, dl{
		padding-bottom: 10px;
        font-size: 1.6rem;
        color: @primaryText;
	}

    ul, ol {
        margin-left: 20px;
    }

    a {
        color: @red;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    h2 {
        font-size: 3.6rem;
        color: @gray-md;
        font-family: @heading;
    }

    h3, h4, h5, h6 {
        padding: 10px 0;
        font-size: 1.6rem;
        color: @primaryText;
        font-family: @main;
    }

    .tablet({
        padding: 90px 30px 60px;
        margin: 0 40px;
    });
}