.block.sitemap {
  padding: 64px 0;
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    * {
      color: @primaryHeading;
      font-size: 3.0rem !important;
      margin-bottom: 20px;
    }
  }
  .nav-list {
    padding: 0 15px;
    width: 100%;
  }
  ul {
    padding-left: 40px;
    margin-bottom: 10px;
  }
  a{
    color: @primaryText;
    &:hover, &:focus{
      color: @primary;
      text-decoration: underline;
    }
    &:has(svg) {
      display: flex;
      align-items: center;
      width: fit-content;
      background-color: @primary;
      border: 1px solid #fff;
      padding: 6px 12px;
      color: #fff;
      font-size: 1.6rem;
      border-radius: 3px;
      svg {
        height: 16px;
        width: 16px;
        margin-right: 5px;
        transition: all 0.3s ease;
        * {
          fill: #fff;
        }
      }
      &:hover, &:focus{
        background-color: @secondary;
        color: #fff;
        text-decoration: none;
        svg {
          * {
            fill: #fff;
          }
        }
      }
    }
  }

  .tablet({
    .inner {
      flex-direction: row;
    }
    .nav-list {
      width: 50%;
      max-width: 450px;
    }
  });
}