// basics
//@import './public/legacy/ledge/src/less/base/_utilities';
@import './base/_settings';
@import './base/_utilities';
@import './base/_normalize';
@import './base/_typography';
@import './base/_forms.less';
@import './base/_base';

@import '../less/parts/floorplans';
@import '../less/parts/floorplan-single';
@import '../less/parts/sitemap';
@import '../less/parts/faq-new';
@import '../less/parts/text';

.box-feature{
	position: relative;
	padding: 125px 20px;
	text-align: center;
	background: url(/assets/img/geo.png) center top no-repeat;
	background-size: cover;

	h5{
		font-size: 3rem;
		line-height: 1.2;
		padding-bottom: 20px;
	}

	a{
		.button;
	}

	.tablet({
		padding: 40vh 20px;
		h5{
			font-size: 5rem;
		}
	});
}

.contact{
	position: relative;
	padding: 45px 20px;

	aside{
		position: relative;

		> div{
			padding: 15px;
			background: #F9F9F9;
			border-bottom: 2px solid @primary;
			margin-bottom: 20px;
		}

		a{
			color: @text;

			&:hover{
				color: @secondary;
				text-decoration: underline;
			}
		}

		p + p{
			padding-top: 10px;
		}
	}



	form{
		margin-top: 25px;

	}

	.contact-form {
		.fields {
			display: flex;
			flex-direction: column;
		}
	}

	.fields{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.field{
		width: 100%;
		margin-bottom: 20px;
	}

	label{
		display: block;
		margin-bottom: 5px;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	aside{
		width: 30%;
	}

	article, .contact-form {
		width: 67%;
	}

	.contact-form .fields {
		flex-direction: row;
	}
});
}

.faqs{
	position: relative;
	padding: 45px 20px;

	h1, h2, h3, h4, h5, h6{
		margin-bottom: 10px;
		min-height: 40px;
		line-height: 40px;
		padding-left: 10px;
		background: @primary;
		color: #fff;
		font-size: 1.8rem;
	}

	p{
		margin-bottom: 20px;

		+ h1, + h2, + h3, + h4, + h5, + h6{
			margin-top: 40px;
		}

		a{
			color: @primary;
			text-decoration: underline;

			&:hover{
				text-decoration: none;
			}
		}
	}

	.faq-item > *{
		display: none;
	}

	.faq-item > h3 {
		display: block;
		cursor: pointer;
	}


.tablet({
	.inner{
		display: flex;
		justify-content: space-between;
	}

	.faq-sidebar{
		width: 300px;
		padding-right: 20px;
		border-right: 1px solid #ddd;

		h3{
			background: none;
			color: @primary;

			&.active{
				background: @primary;
				color: #fff;
			}
		}
	}

	article{
		width:  ~"calc(100% - 341px)";
	}
});

.desktop({

});
}


.feature{
	position: relative;
	padding: 20px;
	article{
		border-bottom: 1px solid @primary;
		margin-bottom: 3px;
		p, ul, ol, dl{
			padding-bottom:  1.5rem;
		}
	}

	aside{
		border-top: 3px solid @primary;

		ul{
			padding-bottom: 45px;
		}

		li{
			list-style: none;
			box-sizing: border-box;
			padding: 45px 15px 0;
			text-align: center;
		}

		img{
			border-radius: 100%;
			margin: 0 auto 25px;
		}

		h1, h2, h3, h4, h5, h6{
			color: @secondaryText;
			font-size: 2.4rem;
		}

		p a{
			.button;
		}
	}

	.tablet({
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		li{
			width: 50%;
			overflow: hidden;
			text-align: left;
			img{
				float: left;
				width: 30%;
				margin: 0 5% 25px 0;
			}

			h3{
				padding-top: 20px;
			}
		}
	})
}


// .floorplan{
// 	padding: 45px 20px;

// 	.inner{
// 		display: flex;
// 		flex-direction: column;
// 	}

// 	aside{
// 		position: relative;
// 		order: 2;
// 	}

// 	.widget{
// 		background: #F1F1F1;
// 		padding: 10px;
// 		border: 1px solid #DADADA;
// 		margin-bottom: 10px;

// 		ul{
// 			list-style: none;
// 			display: flex;
// 		}

// 		li{
// 			max-width: 50%;
// 		}

// 		li + li{
// 			padding-left: 10px;
// 		}

// 		label{
// 			font-weight: bold;
// 		}

// 		h3{
// 			color: @secondaryText;
// 		}
// 	}

// 	article{
// 		order: 1;
// 		position: relative;
// 		background: #F1F1F1;
// 		padding: 10px;
// 		border: 1px solid #DADADA;
// 		margin-bottom: 10px;
// 		list-style: none;

// 		a{
// 			display: block;
// 			color: #333;
// 		}

// 		.thumbnail{
// 			display: block;
// 			padding-bottom: 30px;

// 			img{
// 				width: 100%;
// 				display: block;
// 			}
// 		}

// 		.info{

// 		}

// 		h3{
// 			font-size: 2rem;
// 			color: @secondaryText;
// 			font-weight: bold;
// 			padding-top: 10px;
// 			margin-top: 10px;
// 			border-top: 1px solid #DADADA;
// 		}

// 		dl{
// 			overflow: hidden;
// 			padding: 0 0 10px;
// 			margin: 0;
// 		}

// 		dt{
// 			color: @secondaryText;
// 			text-transform: uppercase;
// 			float: left;
// 			width: 30%;
// 			clear: both;
// 		}

// 		dd{
// 			float: left;
// 		}

// 		p{
// 			clear: both;
// 			font-style: italic;
// 			color: @secondaryText;
// 			font-size: 1.6rem;
// 		}

// 		label{
// 			font-weight: normal;
// 		}
// 	}

// 	table{
// 		width: 100%;
// 		text-align: left;
// 		border-bottom: 1px solid #ddd;

// 		tr{
// 			th{
// 				font-weight: bold;
// 				width: 50%;
// 				margin:0;
// 				padding-left: 8px;
// 				border: 1px solid #ddd;
// 				border-right: 0;
// 				border-bottom:0;
// 			}

// 			td:first-of-type{
// 				padding: 5px;
// 				margin:0;
// 				border: 1px solid #ddd;
// 				border-left: 0;
// 				border-bottom:0;
// 			}
// 		}

// 		tr:nth-child(odd){
// 			margin:0;
// 			background: #F1F1F1;
// 		}
// 	}

// .tablet({
// 	.inner{
// 		flex-direction: row;
// 		width: 100%;
// 		justify-content: space-between;
// 	}

// 	aside{
// 		position: static;
// 		width: 250px;
// 		margin-left: 20px;

// 		form{
// 			padding-top: 10px;
// 		}

// 		label{
// 			font-size: 1.6rem !important;
// 		}

// 		input, textarea{
// 			font-size: 1.7rem !important;
// 		}
// 	}

// 	article{
// 		width: ~"calc(100% - 270px)";
// 		padding:0;
// 		background: none;
// 		border:0;

// 		.active-image{
// 			padding: 10px;
// 			margin-bottom: 25px;
// 			border: 1px solid #ddd;
// 		}

// 		dl{
// 			display: grid;
// 			grid-template-columns: repeat(4, 1fr);
// 			grid-template-rows: repeat(2, 1fr);
// 			grid-column-gap: 0px;
// 			grid-row-gap: 0px;
// 		}

// 		dt{
// 			border-bottom: 1px solid #ddd;
// 			clear: none;
// 			float: none;
// 			text-align: left;
// 			width: 100%;
// 		}

// 		dd{
// 			padding-left:0;
// 			margin-left:0;
// 		}

// 		.g1 { grid-area: 1 / 1 / 2 / 2; }
// 		.g2 { grid-area: 2 / 1 / 3 / 2; }
// 		.g3 { grid-area: 1 / 2 / 2 / 3; }
// 		.g4 { grid-area: 2 / 2 / 3 / 3; }
// 		.g5 { grid-area: 1 / 3 / 2 / 4; }
// 		.g6 { grid-area: 2 / 3 / 3 / 4; }
// 		.g7 { grid-area: 1 / 4 / 2 / 5; }
// 		.g8 { grid-area: 2 / 4 / 3 / 5; }
// 	}

// 	.widget{
// 		margin-bottom: 15px;
// 		.droplist{
// 			display: block;
// 			li{
// 				max-width: 100%;
// 				display: flex;
// 			}

// 			label{
// 				width: 45%;
// 				margin-right: 5%;
// 			}

// 			select{
// 				width: 50%;
// 			}

// 			li + li{
// 				padding: 10px 0 0 0;
// 			}

// 		}
// 	}
// });


// .desktop({

// });
// }


// .floorplans{
// 	padding: 20px;

// 	.inner{
// 		display: flex;
// 		flex-direction: column;
// 	}

// 	aside{
// 		position: relative;
// 		order: 1;
// 	}

// 	.widget{
// 		background: #F1F1F1;
// 		padding: 10px;
// 		border: 1px solid #DADADA;
// 		margin-bottom: 10px;

// 		ul{
// 			list-style: none;
// 			display: flex;
// 		}

// 		li{
// 			max-width: 50%;
// 		}

// 		li + li{
// 			padding-left: 10px;
// 		}

// 		label{
// 			font-weight: normal;
// 		}

// 	}

// 	article{
// 		order: 2;
// 	}

// 	.floorplan{
// 		position: relative;
// 		background: #F1F1F1;
// 		padding: 10px;
// 		border: 1px solid #DADADA;
// 		margin-bottom: 10px;
// 		list-style: none;

// 		a{
// 			display: block;
// 			color: #333;
// 		}

// 		.thumbnail{
// 			display: block;
// 			padding-bottom: 30px;

// 			img{
// 				width: 100%;
// 				display: block;
// 			}
// 		}

// 		.info{

// 		}

// 		h3{
// 			font-size: 2rem;
// 			color: @secondaryText;
// 			padding-bottom: 20px;
// 			font-weight: bold;
// 		}

// 		dl{
// 			overflow: hidden;
// 			padding: 0 0 10px;
// 			margin: 0;
// 		}

// 		dt{
// 			color: @secondaryText;
// 			text-transform: uppercase;
// 			float: left;
// 			width: 30%;
// 			clear: both;
// 		}

// 		dd{
// 			float: left;
// 		}

// 		p{
// 			clear: both;
// 			font-style: italic;
// 			padding-top: 10px;
// 			border-top: 1px solid #DADADA;
// 			color: @secondaryText;
// 			font-size: 1.6rem;
// 		}

// 		label{
// 			font-weight: normal;
// 		}
// 	}

// 	.tablet({
// 		.inner{
// 			flex-direction: row;
// 			width: 100%;
// 			justify-content: space-between;
// 		}

// 		aside{
// 			position: static;
// 			order: 0;
// 			width: 250px;
// 			margin-right: 20px;
// 		}

// 		article{
// 			order: 1;
// 			width: ~"calc(100% - 270px)";
// 		}

// 		.widget{
// 			margin-bottom: 15px;
// 			.droplist{
// 				display: block;
// 				li{
// 					max-width: 100%;
// 					display: flex;
// 				}

// 				label{
// 					width: 45%;
// 					margin-right: 5%;
// 				}

// 				select{
// 					width: 50%;
// 				}

// 				li + li{
// 					padding: 10px 0 0 0;
// 				}

// 			}
// 		}

// 		.floorplan{
// 			margin-bottom: 25px;
// 			a{
// 				display: flex;
// 			}

// 			.thumbnail{
// 				width:20%;
// 				margin-right: 3%;
// 			}

// 			.info{
// 				width: 77%;
// 			}

// 			img{
// 				display: block;
// 				width: 100%;
// 			}
// 		}
// 	});


// 	.desktop({

// 	});
// }


#footer{
	position: relative;
	padding: 45px 20px;
	margin-top: -3px;
	background: @primary;
	color: #fff;
	text-align: center;

	.col{
		padding: 0 5px 40px;
	}

	a{
		color: #fff;

		&:hover{
			text-decoration: underline;
		}
	}

	.social-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 5px;
		.social {
			a {
				border-radius: 5px;
				transition: all 0.3s ease;
				padding: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					height: 20px;
					width: 20px;
					* {
						fill: #fff;
						transition: fill 0.3s ease;
					}
				}
				&:hover, &:focus {
					background-color: @secondary;

				}
			}
			&:has(a[href=""]) {
				display: none;
			}
		}
	}

	h6{
		font-size: 2rem;
		margin: 0;
	}

	a.button{
		.button;
		// line-height: 40px;
		margin-bottom: 10px;
		// max-width: 70%;
		// margin: auto;
		color: #fff;
		background: #333;
		line-height: 1.5;
		font-size: 1.6rem !important;
		height: fit-content;
		width: fit-content;
		padding: 6px 12px;
    margin: 0 auto 10px;
		border: 1px solid #fff;
		svg {
			height: 16px;
			width: 16px;
			margin-right: 5px;
			* {
				fill: #fff;
			}
		}
		&:hover, &:focus {
			text-decoration: none;
		}
	}

.tablet({
	.inner{
		position: relative;
		display: flex;
	}

	.col{
		flex: 1;
	}
});
}


.gallery{
	position: relative;
	padding: 20px;

	ul{
		list-style: none;
		overflow: hidden;

		li{
			position: relative;
			overflow: hidden;
			&:before {
				content: "";
				display: block;
				padding-top: 100%;
				/* initial ratio of 1:1*/
			  }
		}

		a{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background: #000;
			overflow: hidden;
		}

		img{
			display: block;
			min-width: 100%;
			object-fit: cover;
			height: 100%;
			transition: ease all .3s;
		}

		a:hover{
			img{
				transform: scale(1.2);
				opacity: .7;
			}
		}

		li:nth-of-type(1),
		li:nth-of-type(7),
		li:nth-of-type(13),
		li:nth-of-type(19),
		li:nth-of-type(25),
		li:nth-of-type(31),
		li:nth-of-type(37){
			float: left;
			width: 66%;
			margin-bottom: 1%;
		}

		li:nth-of-type(2),
		li:nth-of-type(8),
		li:nth-of-type(14),
		li:nth-of-type(20),
		li:nth-of-type(26),
		li:nth-of-type(32),
		li:nth-of-type(38){
			float: right;
			width: 32.5%;
			margin-bottom: 0%;
		}

		li:nth-of-type(3),
		li:nth-of-type(9),
		li:nth-of-type(15),
		li:nth-of-type(21),
		li:nth-of-type(27),
		li:nth-of-type(33),
		li:nth-of-type(39){
			float: right;
			width: 32.5%;
			margin-top: 1%;
		}


		li:nth-of-type(4),
		li:nth-of-type(10),
		li:nth-of-type(16),
		li:nth-of-type(22),
		li:nth-of-type(28),
		li:nth-of-type(34),
		li:nth-of-type(40){
			position: relative;
			float: left;
			clear: left;
			width: 32.5%;
		}

		li:nth-of-type(5),
		li:nth-of-type(11),
		li:nth-of-type(17),
		li:nth-of-type(23),
		li:nth-of-type(29),
		li:nth-of-type(35),
		li:nth-of-type(41){
			float: left;
			clear: left;
			width: 32.5%;
			margin-top: 1%;
		}

		li:nth-of-type(6),
		li:nth-of-type(12),
		li:nth-of-type(18),
		li:nth-of-type(24),
		li:nth-of-type(30),
		li:nth-of-type(36),
		li:nth-of-type(42){
			position: relative;
			float: right;
			width: 66%;
			margin-bottom: 1%;
			margin-top: -32.5%;
		}
	}
}


#header {
	position: relative;
	padding: 0 20px;
	background: #333;
	text-align: center;
	height: 100vh;
	display: flex;
	flex-direction: column;
	color: #fff;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
	&.alt{
		height: 200px;
		padding-top: 5vh;
		background: #fff;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
	}

	&:before{
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		content:"";
		opacity: .4;
	}
	&:not(.alt) {
		&:before{
			background: linear-gradient(to right, #1D795F 0%, #501324 100%);
		}
	}
	&.alt {
		&:before{
			background: rgba(255, 235, 235, 1);
		}
	}

	h1{
		padding-bottom: 8vh;
		font-size: 6rem;
		color: @homePageHeading;
	}

	&.alt h1 {
		color: @primary;
		font-size: 3.2rem;
		padding-bottom: 0;
	}

	p{
		padding-bottom: 1em;
	}

	.editable{
		a{
			.button;
			margin: auto;
		}
	}

	a.special{
		display: block;
		margin: auto;
		max-width: 380px;

		img{
			display: block;
			width: 100%;
		}
	}

	.tablet({
		&.alt {
			height: 400px;
			h1 {
				font-size: 6.4rem;
			}
		}
	});

	.desktop({
		&.alt {
			height: 600px;
			h1 {
				font-size: 9.6rem;
			}
		}
	});
}


#navwrap{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;

	#nav{
		position: relative;
		padding: 20px 15px;
	}

	.inner{
		position: relative;
		.clear;
	}

	#logo{
		position: relative;
		float: left;
		display: block;
		margin-left:0;
		// height: 60px;
		min-width: 60px;
		height: auto;
		max-height: 165px;
		width: 25%;
		max-width: 165px;
		line-height: 45px;
		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
		text-shadow: 0 0 15px rgba(0,0,0,.4);
		z-index: 500;
		margin-left: 15px;

		img{
			display: block;
			max-width: 100%;
			max-height: 100%;
		}
	}

	#grip{
		position: absolute;
		top:0;
		right:10px;
		display: block;
		float: right;
		width: 45px;
		height: 39px;
		background: @primary;
		cursor: pointer;
		z-index: 16000;
		transition: ease all .3s;

		&:before,
		&:after{
			position: absolute;
			display: block;
			top: 9px;
			left:9px;
			right:9px;
			height: 4px;
			border-radius: 2px;
			background: #fff;
			content:"";
		}

		&:after{
			top: auto;
			bottom: 9px;
		}


		span{
			position: absolute;
			display: block;
			top: 18px;
			left: 9px;
			right:9px;
			height: 4px;
			border-radius: 2px;
			background: #fff;
			text-indent: -9999em;
			text-align: left;
		}

		&:hover{
			background: @secondary;
		}
	}

	.tablet({
		#logo{
			margin-left: 0;
		}
	});
}

#menu-wrap{
	position: fixed;
	top:0;
	right:0;
	bottom:0;
	width: 320px;
	max-width: 90vw;
	z-index: 100;
	background: #7A482D;
	display: none;
}


.tablet({
position: relative;

});


.testimonials{
	position: relative;
	padding: 45px 20px;

	.testimonial{
		padding: 15px;
		box-shadow: 0 0 15px rgba(0,0,0,.2);
	}

	.testimonial + .testimonial{
		margin-top: 20px;
	}
.tablet({

});

.desktop({

});
}


.tricol{
	position: relative;
	padding: 45px 20px;

	.inner{
		overflow: hidden;


	}

	.col{
		position: relative;
		padding: 0;
		margin-bottom: 20px;
	}

	h3{
		margin-bottom: 8px;
		color: @secondaryText;
	}

	img{
		display: block;
		width: 100%;
		aspect-ratio: 3/2;
		object-fit: cover;
	}

	ul{
		list-style: inside disc;
		margin: 0;
		padding: 6px 0 0 12px;

		li{
			padding: 5px 0;
		}
	}

	.tablet({
		.inner{
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
		}

		.col{
			width: calc(33.333% - 7px);
			padding: 0;
			margin-bottom: 20px;
		}
	});

}


.triple{
	// background-color: @primary;
	color: @white;
	padding: 0;

	.inner{
		div{
			position: relative;
		}
			width: 100%;
			max-width: 100%;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 9;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 80px 100vw 0 0;
				border-color: #fff transparent transparent transparent;
				-moz-transform: scale(0.9999);
				pointer-events: none;
			}
			&:after {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 9;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 0 80px 100vw;
				border-color: transparent transparent @primary transparent;
				-moz-transform: scale(0.9999);
				pointer-events: none;
			}

		a{
			position: relative;
			display: block;
			color: #fff;
			&:before{
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: rgba(0,0,0,.5);
				content: "";
				transition: ease all .3s;
				pointer-events: none;
			}


			&:hover, &:focus {
				text-shadow: 1px 1px 2px rgba(0,0,0,1);
				&:before {
					background: rgba(0,0,0,0);
				}
			}
		}

		strong{
			position: absolute;
			display: block;
			top: 45%;
			left:0;
			right: 0;
			font-size: 2.4rem;
			text-align: center;
		}

	}


	.tablet({
		.inner{
			display: flex;
		}

		+ markup > #footer{
			// margin-top: -80px;
		}
	});
}

#mainwrap .triple:last-child .inner:after{
	border-color: transparent transparent @primary transparent;
}


#welcome{
	position: relative;
	background: url(/assets/img/shatter.png) center no-repeat;
	background-size: cover;
	padding: 50px 0 160px;

	article{
		position: relative;
		width: 900px;
		margin: auto;
		max-width: 90vw;
		background: #fff;
		padding: 30px;
		box-sizing: border-box;

		a{
			color: @primary;
			background: #fff;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	footer{
		position: relative;
		width: 900px;
		margin: auto;
		max-width: 90vw;
		display: flex;
		background: transparent;

		a{
			width: 90%;
			display: block;
			position: relative;
			height: 50px;
			line-height: 50px;
			background: @primary;
			text-align: center;
			color: #fff;
			text-decoration: none;
			font-weight: bold;
			transition: ease all .3s;
			margin: auto;
			&::after {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #fff;
				opacity: 0;
				transition: ease opacity 0.3s;
				pointer-events: none;
			}
			&:hover{
				&::after {
					opacity: .3;
				}
			}
		}

		a + a{
			background: @secondary;
		}

		 a+a+a{
			background: @primary;
		 }
	}

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		border-style: solid;
		border-width: 80px 0 0 50vw;
		border-color: transparent transparent transparent #fff;
		-moz-transform: scale(0.9999);
	}

	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		bottom: 0;
		right: 0;
		border-style: solid;
		border-width: 0 0 100px 50vw;
		border-color: transparent transparent #fff transparent;
		-moz-transform: scale(0.9999);
	}
}

.generic-supernav-overlay{
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index: 15000;

	&:before{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content:"";
		// backdrop-filter: blur(2px);
		background: #222;
		opacity: .85;
	}

	.primary{
		padding: 40px 20px 0;

		a{
			color: #fff;
			line-height: 1.4;
			display: block;
			margin-bottom: 10px;
			&:hover, &:focus{
				color: @primary;
			}
			&:has(svg) {
				display: flex;
				align-items: center;
				width: fit-content;
				background-color: @primary;
				border: 1px solid #fff;
				padding: 6px 12px;
				color: #fff;
				font-size: 1.6rem;
				border-radius: 3px;
				line-height: 1.75;
				svg {
					height: 16px;
					width: 16px;
					margin-right: 5px;
					transition: all 0.3s ease;
					* {
						fill: #fff;
					}
				}
				&:hover, &:focus{
					background-color: @secondary;
					color: #fff;
					svg {
						* {
							fill: #fff;
						}
					}
				}
			}
		}
	}

	.tablet({
			.primary {
				a{
					&:has(svg) {
						font-size: 2.2rem;
						svg {
							height: 20px;
							width: 20px;
						}
					}
				}
			}
	});


	.secondary{
		padding: 30px 20px 0;
	}


	.generic-social{
		padding-bottom: 30px;
		color: #fff;

		li{
			list-style: none;
			display: inline-block;
			padding-right: 20px;
		}

		a{
			color: #fff;
			font-size: 24px;
			display: block;
			height: 55px;
			line-height: 55px;
			width: 55px;
			border-radius: 100%;
			background: @primary;
			text-align: center;

			&:hover{
				background: #fff;
				color: @primary;
			}
		}

		span{
			display: none;
		}
	}


	.generic-contact{
		padding: 20px;
		background: @primary;
		color: #fff;
		font-size: 1.4rem;
		line-height: 1.35;

		strong{
			font-size: 1.6rem;
			font-weight: bold;
			padding-bottom: 10px;
			display: block;
		}

		a{
			color: #fff;

			&:hover{
				color: @primary;
			}
		}

		p + p{
			padding-top: 10px;
		}
	}


	.generic-logo{
		display: none;
		a {
			display: block;
			width: 100%;
			height: 100%;
		}
		img{
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}

.tablet({
	display: flex;

	.inner{
		height: 100%;
		display: flex;
	}

	.primary{
		width: 60%;
		display: flex;
		align-items: center;
		font-size: 3rem;
	}

	.secondary{
		position: relative;
		width: 40%;
		background: #fff;
		display: flex;
		align-items: center;

		&:before{
			position: absolute;
			top:0;
			left: 0;
			right: -10000px;
			bottom:0;
			content:"";
			background: #fff;
		}
	}

	.generic-logo{
		display: block;
		height: 20vh;
    max-height: 200px;
    min-height: 100px;
	}

	.in{
		position: relative;
		width: 100%;
	}

	.generic-social{
		padding: 75px 0;

		// li{
		// 	display: block;
		// 	padding-bottom: 10px;
		// 	overflow: hidden;
		// }

		// a{
		// 	float: left;
		// }

		// span{
		// 	height: 55px;
		// 	line-height: 55px;
		// 	padding-left: 10px;
		// 	color: @primary;
		// }
	}

	.generic-contact{
		background: none;
		padding: 0;
		color: #000;

		a{
			color: #000;
		}
	}
});
}

.wow {
	opacity: 0;
	visibility: hidden;
	animation-fill-mode: forwards;
}

#rs-builder {
	.wow {
		opacity: 1 !important;
		visibility: visible !important;
		animation-fill-mode: forwards;
	}
}