.block.faqs-new{
	position: relative;
	padding: 45px 20px;

  .inner {
    display: flex;
    flex-direction: column;
    .faq-list, .faq-answer-holder {
      width: 100%;
    }
  }

  .faq-list {
    list-style: none;
    // border-bottom: 1px solid #ddd;
    padding: 0 0 20px;
    margin: 0 0 20px;
  }

  .faq-answer {
    display: block;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease;
  }

  .faq-question {
    background: none;
    margin-bottom: 10px;
    min-height: 40px;
    line-height: 40px;
    padding-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: @primary;
    * {
      color: #fff;
      font-size: 1.8rem;
    }
  }

  .faq-answer-holder {
    display: none;
  }

	.faq-answer-holder, .faq-answer {
    p{
      margin-bottom: 20px;

      + h1, + h2, + h3, + h4, + h5, + h6{
        margin-top: 40px;
      }

      a{
        color: @primary;
        text-decoration: underline;

        &:hover{
          text-decoration: none;
        }
      }
    }
  }


.tablet({
  .inner {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .faq-list {
      width: 300px;
      padding: 0 20px 0 0;
      margin: 0;
      border-bottom: 0;
      border-right: 1px solid #ddd;
    }
    .faq-question {
      background: transparent;
      * {
        color: @primary;
      }
      &.active{
        background: @primary;
        * {
          color: #fff;
        }
      }
    }
    .faq-answer {
      display: none;
    }
    .faq-answer-holder {
      display: block;
      width: calc(100% - 340px);
    }
  }
});
}

#rs-builder {
  .faqs-new {
    .faq-answer {
      display: block;
      height: auto;
    }
    .faq-question {
      background: @primary;
      * {
        color: #fff;
      }
    }
    .faq-answer-holder {
      display: none;
    }
    .faq-list {
      width: 100%;
    }
  }
}