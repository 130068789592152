.block.floorplan-single {
  * {
    box-sizing: border-box;
  }
  padding: 80px 17px;

  .fp-details {
    padding: 0 15px;
    .image-wrapper {
      width: 100%;
      height: auto;
      padding: 0 15%;
      background-color: black;
      button {
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: unset;
      }
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .specs {
      margin-bottom: 30px;
      ul {
        margin: 0;
      }
      .title {
        padding: 20px 15px;
        h2, h3, h4, h5 {
          line-height: 1;
          font-size: 1.8rem;
          font-weight: 400;
        }
        background-color: #f1f1f1;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(1, 4fr);
        list-style: none;
        border-right: 1px solid #cccccc;
        border-left: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        background-color: #f1f1f1;
        li {
          padding: 8px 15px 0;
          line-height: 2;
          color: @heading;
          opacity: 0.9;
          display: flex;
          flex-direction: row;
          align-items: center;
          &:not(:last-child) {
            border-bottom: 1px solid #cccccc;
          }
          strong, p {
            font-size: 1.2rem;
          }
          strong {
            margin-right: 5px;
            text-transform: uppercase;
            opacity: 1;
            font-weight: 700;
          }
        }
      }
    }
    .description {
      background-repeat: repeat;
      margin-bottom: 30px;
      h3 {
        font-size: 1.8rem;
      }
      .descriptive-text {
        p {
          margin-top: 10px;
          color: @heading;
          opacity: 0.9;
        }
      }
    }
    .additional {
      position: relative;
      min-height: 150px;
      border: 1px solid #cccccc;
      h4 {
        background-color: #cccccc;
        padding: 8px 15px;
        font-size: 2.0rem;
        font-weight: 400;
      }
      .fp-owl-carousel {
        .owl-prev, .owl-next {
          display: none;
        }
        display: flex;
        padding: 20px 0 8px;
        margin-right: 56px;
        overflow: hidden;
        .owl-stage {
          display: flex;
        }
        .item {
          img {
            max-height: 70px;
            width: 90px;
            object-fit: contain;
            margin-bottom: 10px;
          }
          .image, .item-details {
            padding: 0 10px;
            h5 {
              font-size: 1.8rem;
              margin-bottom: 3px;
            }
            .bed-bath {
              line-height: 1;
            }
            span {
              font-size: 1.2rem;
              color: @primaryText;
            }
          }
        }
        .vp(375px, {
          .item {
            display: flex;
            flex-direction: row;
          }
        });
      }
      .owlNext {
        background: transparent;
        border: 1px solid black;
        border-radius: 0;
        min-width: unset;
        padding: 1px 6px;
        position: absolute;
        right: 1.5rem;
        bottom: 5.5rem;
        i {
          color: black;
          font-size: 2rem;
        }
      }
    }
  }
  .contact-info {
    padding: 0 15px;
    .title {
      margin: 10px 0;
    }
    .v-tour, .box {
      margin-bottom: 16px;
      width: 100%;
    }
    .v-tour {
      .vt-btn {
        display: none;
        padding: 18px 15px;
        background-color: red;
        border-radius: 0;
        width: 100%;
        min-width: unset;
        font-size: 1.4rem;
        font-weight: 400;
        transition: none;
        justify-content: center;
        align-items: center;
        &:hover, &:focus {
          color: @primaryText;
        }
      }

    }
    .box {
      padding: 18px 15px 8px;
      background-color: #f1f1f1;
      border: 1px solid #cccccc;
      a, p, label, .form-group span {
        font-size: 1.36rem;
      }
      a {
        color: @primary;
        &:hover, &:focus {
          text-decoration: underline;
        }
      }
      form {
        label {
          color: @primaryHeading !important;
          font-size: 1.2rem;
        }
        .form-group {
          margin-bottom: 12px;
          // In event required attribute is not available, use class .required
          &.required, &:has(input[required]) {
            label::after {
              color: @primaryText;
            }
            input {
              border: 1px solid #cccccc;
            }
          }
        }
        input[type="submit"], input[type="button"], input[type="reset"], button {
            .button;
            border: 1px solid #ccc;
            font-size: 1.2rem;
            width: 100%;
            cursor: pointer;
        }
        .fields {
          color: @primaryText;
        }
      }
    }
  }

  .tablet({
    .fp-details {
      .specs {
        ul {
          grid-template-columns: repeat(2, 2fr);
          li {
            &:not(:last-child) {
              border-bottom: none;
            }
            &:nth-child(odd) {
              border-right: 1px solid #cccccc;
            }
            &:nth-child(1), &:nth-child(2) {
              border-bottom: 1px solid #cccccc;
            }
          }
        }
      }
    }
  });

  .vp(1000px, {
    .inner {
      display: flex;
      flex-direction: row;
    }
    .fp-details {
      width: 75%;
      .specs {
        ul {
          display: flex;
          flex-direction: row;
          border: none;
          background-color: transparent;
          li {
            flex: 1;
            border: none !important;
            display: flex;
            flex-direction: column;
            align-items: unset;
            padding: 15px 0 0;
            strong {
              display: block;
              width: 100%;
              border-bottom: 1px solid @secondary;
              opacity: 0.5;
              font-weight: 400;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    .contact-info {
      width: 25%;
    }
  });
}